import React from "react"
import PropTypes from "prop-types"
import axios from "axios"

import { AddressLookup } from "../../Shared"

export const Address = ({ order, updateOrder, updateSteps }) => {
  const saveUserAddress = async (
    values,
    setSubmitting,
    existingData,
    updateExistingData
  ) => {
    updateExistingData((order) => ({
      ...order,
      steps: {
        ...order.steps,
        address: {
          ...order.steps.address,
          result: values.line1,
          data: values,
        },
      },
    }))
    setSubmitting(false)
    updateSteps()
  }

  return (
    <AddressLookup
      successFunc={saveUserAddress}
      existingData={order}
      updateExistingData={updateOrder}
      nextButtonText="Enter your contact details"
    />
  )
}

Address.propTypes = {
  order: PropTypes.object.isRequired,
  updateOrder: PropTypes.func.isRequired,
}
