import React from "react"
import PropTypes from "prop-types"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import { PayFinance } from "./PayFinance"

export const Payment = ({ setOrderCompleted, planId, totalPrice }) => {
  const [stripe] = React.useState(() =>
    loadStripe(process.env.GATSBY_STRIPE_PK)
  )
  return (
    <div className="payment-options">
      <section className="payment-cover-plan">
        <h3 className="payment-cover-plan-heading">Pay monthly</h3>
        <Elements stripe={stripe}>
          <PayFinance
            planId={planId}
            setOrderCompleted={setOrderCompleted}
            totalPrice={totalPrice}
          />
        </Elements>
      </section>
    </div>
  )
}

Payment.propTypes = {
  setOrderCompleted: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
}
