import React from "react"
import { graphql, Link } from "gatsby"
import axios from "axios"
import SnackbarProvider from "react-simple-snackbar"

import { CoverCheckout } from "../../components/CoverCheckout"
import { CoverCheckoutConfirmation } from "../../components/CoverCheckout/Confirmation"
import { Loader, Seo } from "../../components/Shared"

const HomeCoverCheckout = ({ data, location }) => {
  // Needed incase wrapper div isn't full height of screen
  React.useEffect(() => {
    // Making background light grey on mount
    document.body.classList.add("background-grey-lightest")
    return () => {
      // Resetting background colour on unmount so colour doesn't persist on future pages
      document.body.classList.remove("background-grey-lightest")
    }
  }, [])

  const [orderCompleted, setOrderCompleted] = React.useState(false)
  const [loader, setLoader] = React.useState({
    error: false,
    loading: true,
  })

  const [order, updateOrder] = React.useState({
    useCalloutFees: true,
    homeCoverPlanId: "",
    planId: "",
    planNumber: 0,
    steps: {
      address: {
        number: 1,
        name: "Your address",
        description: "Which property would you like to cover?",
        result: "",
        completed: false,
        disabled: false,
        data: {
          city: "",
          line1: "",
          line2: "",
          line3: "",
          postalCode: "",
          region: "",
        },
      },
      contact: {
        number: 2,
        name: "Contact",
        description: "What are your contact details?",
        result: "",
        completed: false,
        disabled: true,
        data: {
          email: "",
          homePhone: "",
          mobile: "",
          firstName: "",
          lastName: "",
        },
      },
      calloutFee: {
        number: 3,
        name: "Callout fee",
        description: "How much would you like to pay if you make a claim?",
        result: "",
        completed: false,
        disabled: true,
        data: {
          id: "",
          name: "",
          monthlyPrice: 0,
          calloutFee: 0,
        },
      },
      payment: {
        number: 4,
        name: "Payment",
        description:
          "You’re almost done! We just need to set up your direct debit",
        result: "",
        completed: false,
        disabled: true,
        data: {},
      },
    },
    breakdown: {
      rows: [],
      total: "",
    },
  })
  const allCoverPlans = data.allContentfulHomeCoverPlans.edges

  const getPlanDetails = (plans, planId) => {
    let plan
    plans.map(({ node }) => planId === node.contentful_id && (plan = node))
    return plan
  }

  const formatBreakdown = (plan) => {
    let breakdownRows = [
      {
        id: "1",
        label: `${plan.name} plan`,
        price: `£${plan.monthlyPrice}/mo`,
      },
    ]

    plan.calloutFees &&
      plan.defaultCalloutFee !== null &&
      breakdownRows.push({
        id: "2",
        label: "Callout fee: " + plan.defaultCalloutFee.name,
        price:
          plan.defaultCalloutFee.monthlyPrice === 0
            ? "FREE"
            : plan.defaultCalloutFee.monthlyPrice,
      })

    plan.boilerRepair &&
      breakdownRows.push({
        id: "3",
        label: "Boiler & controls repair cover",
        price: "FREE",
      })

    plan.CP12 &&
      breakdownRows.push({
        id: "4",
        label: "Annual boiler service (CP12)",
        price: "FREE",
      })

    plan.heatingRepairs &&
      breakdownRows.push({
        id: "5",
        label: "Central heating repairs cover",
        price: "FREE",
      })

    plan.plumbingRepairs &&
      breakdownRows.push({
        id: "6",
        label: "Plumbing repairs cover",
        price: "FREE",
      })

    plan.electricalRepairs &&
      breakdownRows.push({
        id: "7",
        label: "Electrical repairs cover",
        price: "FREE",
      })

    plan.freeSystemFlush &&
      breakdownRows.push({
        id: "8",
        label: "Magnacleanese system flush",
        price: "FREE",
      })

    breakdownRows.push({
      id: "9",
      label: "Maximum claims per year",
      price: plan.maxClaimsPerYear,
    })

    return {
      rows: breakdownRows,
      total: plan.monthlyPrice,
    }
  }

  React.useEffect(() => {
    const locationArray = window.location.href.split("?plan=")
    const planOrderIdFromURL = locationArray[locationArray.length - 1]
    const homeCoverPlan = getPlanDetails(allCoverPlans, planOrderIdFromURL)

    const createNewPlanOrder = async () => {
      try {
        const createPlanOrder = await axios({
          method: "post",
          url: `${process.env.GATSBY_API_BASE_URL}/plan/create`,
          data: {
            homeCoverPlanId: planOrderIdFromURL,
          },
        })

        const { planNumber, planId } = createPlanOrder.data

        localStorage.setItem("planId", planId)
        localStorage.setItem("planNumber", planNumber)
        localStorage.setItem("homeCoverPlanId", planOrderIdFromURL)

        updateOrder((order) => ({
          ...order,
          planId: planId,
          planNumber: planNumber,
          homeCoverPlan: homeCoverPlan,
          homeCoverPlanBaseCost: homeCoverPlan.monthlyPrice,
          homeCoverPlanId: planOrderIdFromURL,
          useCalloutFees: homeCoverPlan.calloutFees,
          steps: {
            address: { ...order.steps.address },
            contact: { ...order.steps.contact },
            /* Removing calloutFee key by rebuilding order without it if homeCoverPlan.calloutFee !== true*/
            ...(homeCoverPlan.calloutFees && {
              calloutFee: { ...order.steps.calloutFee },
            }),
            payment: {
              ...order.steps.payment,
              number: homeCoverPlan.calloutFees ? 4 : 3,
            },
          },
          breakdown: formatBreakdown(homeCoverPlan),
        }))

        setLoader((loader) => ({
          ...loader,
          error: false,
          loading: false,
        }))
      } catch (error) {
        setLoader((loader) => ({
          ...loader,
          error: true,
          loading: false,
        }))
      }
    }

    if (
      localStorage.getItem("planId") &&
      localStorage.getItem("planNumber") &&
      localStorage.getItem("homeCoverPlanId")
    ) {
      if (localStorage.getItem("homeCoverPlanId") === planOrderIdFromURL) {
        updateOrder((order) => ({
          ...order,
          planId: localStorage.getItem("planId"),
          planNumber: localStorage.getItem("planNumber"),
          homeCoverPlan: homeCoverPlan,
          homeCoverPlanBaseCost: homeCoverPlan.monthlyPrice,
          homeCoverPlanId: planOrderIdFromURL,
          useCalloutFees: homeCoverPlan.calloutFees,
          steps: {
            address: { ...order.steps.address },
            contact: { ...order.steps.contact },
            /* Removing calloutFee key by rebuilding order without it if homeCoverPlan.calloutFee !== true*/
            ...(homeCoverPlan.calloutFees && {
              calloutFee: { ...order.steps.calloutFee },
            }),
            payment: {
              ...order.steps.payment,
              number: homeCoverPlan.calloutFees ? 4 : 3,
            },
          },
          breakdown: formatBreakdown(homeCoverPlan),
        }))

        setLoader((loader) => ({
          ...loader,
          error: false,
          loading: false,
        }))
      } else {
        createNewPlanOrder()
      }
    } else {
      createNewPlanOrder()
    }
  }, [])

  if (loader.loading || loader.error) {
    return (
      <>
        <Seo
          url={
            data.site &&
            data.site.siteMetadata.url + "/home-cover-plans/checkout"
          }
          title="Checkout | Home Assist Repairs and Servicing"
        />
        <Loader
          text="Setting up checkout"
          error={loader.error}
          errorText="Could not find plan details"
          errorButton={
            <div className="loader-buttons">
              <Link className="button-primary" to="/home-cover-plans">
                Choose a different plan
              </Link>
            </div>
          }
        />
      </>
    )
  } else {
    return orderCompleted ? (
      <CoverCheckoutConfirmation order={order} location={location} />
    ) : (
      <SnackbarProvider>
        <CoverCheckout
          order={order}
          updateOrder={updateOrder}
          setOrderCompleted={setOrderCompleted}
        />
      </SnackbarProvider>
    )
  }
}

export default HomeCoverCheckout

export const pageQuery = graphql`
  query getAllCoverPlansAndCalloutFees {
    site {
      siteMetadata {
        url
      }
    }
    allContentfulHomeCoverPlans(sort: { order: ASC, fields: createdAt }) {
      edges {
        node {
          CP12
          maxClaimsPerYear
          boilerRepair
          contentful_id
          electricalRepairs
          freeSystemFlush
          heatingRepairs
          monthlyPrice
          name
          plumbingRepairs
          replacementBoilerOver7Years
          replacementBoilerUnder7Years
          shortDescription
          calloutFees
          calloutFeeOptions {
            id
            contentful_id
            name
            calloutFee
            monthlyPrice
          }
          defaultCalloutFee {
            id
            contentful_id
            name
            calloutFee
            monthlyPrice
          }
        }
      }
    }
  }
`
