import React from "react"
import dayjs from "dayjs"

import {
  Layout,
  BreakDownTable,
  ConfirmationPageSection,
  ConfirmationPage,
  DataDisplay,
  Seo,
} from "../../Shared"
import { convertToDateWithSuffix } from "../../../utilities"

import BlobWide from "../../../images/Blob-wide.svg"
import House from "../../../images/illustrations/services/landlord-services.svg"
import ShieldCircle from "../../../images/shield-circle.svg"

export const CoverCheckoutConfirmation = ({ order, location }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const user = order.steps.contact.data
  const address = order.steps.address.data

  const firsClaimPossible = dayjs().add(14, "day")
  return (
    <Layout minimal={true}>
      <Seo
        url={location && location.href ? location.href : null}
        title="You're Covered | Home Assist Repairs and Servicing"
      />
      <ConfirmationPage
        large={true}
        heading="You're covered"
        followUp={`First claim possible: ${convertToDateWithSuffix(
          dayjs(firsClaimPossible).format("D")
        )} ${dayjs(firsClaimPossible).format("MMMM")}`}
        note="We may contact you to survey your property"
      >
        <ConfirmationPageSection>
          <div>
            <div className="confirmation-home-cover">
              <BlobWide />
              <House />
              <ShieldCircle />
            </div>
          </div>
          <div>
            <h2 className="heading-small">Plan #{order.planNumber}</h2>
            <p style={{ marginTop: "0.5rem" }}>
              Confirmation has been sent to your email
            </p>
            <BreakDownTable useStrings={true} rows={order.breakdown.rows} />
            <p>Payments will be taken on the date of purchase each month.</p>
          </div>
        </ConfirmationPageSection>
        <ConfirmationPageSection>
          <DataDisplay label="Contact">
            {user ? (
              <>
                {user.firstName && user.lastName && (
                  <>
                    {user.firstName + " " + user.lastName}
                    <br />
                  </>
                )}
                {user.email && (
                  <>
                    {user.email}
                    <br />
                  </>
                )}
                {user.mobile && (
                  <>
                    {user.mobile}
                    <br />
                  </>
                )}
                {user.homePhone && (
                  <>
                    {user.homePhone}
                    <br />
                  </>
                )}
              </>
            ) : (
              "No details found"
            )}
          </DataDisplay>
          <DataDisplay label="Address">
            {address ? (
              <>
                {address.line1 && (
                  <>
                    {address.line1},<br />
                  </>
                )}
                {address.line2 && (
                  <>
                    {address.line2},<br />
                  </>
                )}
                {address.city && (
                  <>
                    {address.city},<br />
                  </>
                )}
                {address.region && (
                  <>
                    {address.region},<br />
                  </>
                )}
                {address.postalCode && address.postalCode}
              </>
            ) : (
              "No address found"
            )}
          </DataDisplay>
        </ConfirmationPageSection>
      </ConfirmationPage>
    </Layout>
  )
}
