import React from "react"
import PropTypes from "prop-types"

import { Modal } from "../../Shared"

export const BoilerReplacementScheme = ({ isVisible, dismissFunc }) => {
  return (
    <Modal
      heading="Boiler replacement scheme"
      isVisible={isVisible}
      dismissFunc={dismissFunc}
    >
      <div className="rich-text">
        <p>
          By purchasing a Home Assist Repairs and Servicing Cover Plan you're
          entitled to a replacement boiler if your old boiler can't repaired.
        </p>
        <ul>
          <li>
            If your boiler is less than 7 years old and can't be repaired we'll
            replace your boiler for FREE
          </li>
          <li>
            If your boiler is more than 7 years old and can't be repaired we'll
            give you 50% OFF a new boiler
          </li>
        </ul>
      </div>
    </Modal>
  )
}

BoilerReplacementScheme.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  dismissFunc: PropTypes.func.isRequired,
}
