import React from "react"
import axios from "axios"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"

import { ButtonWithLoaderPayment, BreakDownTable } from "../../../Shared"
import { convertPriceToPounds } from "../../../../utilities"
import { CoverOrderContext } from "../../"

export const PayFinance = ({ planId, setOrderCompleted, totalPrice }) => {
  const order = React.useContext(CoverOrderContext)
  const [error, setError] = React.useState(null)
  const [disabled, setDisabled] = React.useState(true)
  const [processing, setProcessing] = React.useState(false)
  const [succeeded, setSucceeded] = React.useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const [retrySubscription, setRetrySubscription] = React.useState(false)
  const [
    retrySubscriptionInvoiceId,
    setRetrySubscriptionInvoiceId,
  ] = React.useState(false)

  const completeGoogleAnalyticsConversion = async () => {
    let products = [
      {
        sku: order.homeCoverPlanId,
        name: order.breakdown.rows[0].label,
        category: "Home Cover Plan",
        price: order.homeCoverPlanBaseCost,
        quantity: 1,
      },
    ]

    if (order.useCalloutFees && order.steps.calloutFee !== undefined) {
      products.push({
        sku: order.steps.calloutFee.data.id,
        name: order.steps.calloutFee.data.name,
        category: "Callout fee",
        price: order.steps.calloutFee.data.monthlyPrice,
        quantity: 1,
      })
    }

    try {
      dataLayer.push({
        event: "purchase",
        transactionId: order.planId,
        transactionAffiliation: "HomeAssist.Services Ltd",
        transactionTotal: order.breakdown.total,
        transactionTax: 0,
        transactionShipping: 0,
        transactionProducts: products,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const stripeOptions = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#222",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#999",
        },
      },
      invalid: {
        color: "#d62b62",
        iconColor: "#fa755a",
      },
    },
  }

  const handleChange = async (event) => {
    setDisabled(event.empty)
    setError(event.error ? event.error.message : "")
  }

  const handleSubmit = async (ev, isRetry) => {
    ev.preventDefault()
    setProcessing(true)
    stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      })
      .then((result) => {
        if (result.error) {
          setError(
            "Error setting up subscription, please contact customer support on 0191 406 0888"
          )
        } else {
          createSubscription(result.paymentMethod.id, isRetry)
        }
      })
  }

  const createSubscription = async (paymentMethodId, isRetry) => {
    let data

    if (isRetry) {
      data = {
        planId: planId,
        paymentMethodId: paymentMethodId,
        invoiceId: retrySubscriptionInvoiceId,
      }
    } else {
      data = { planId: planId, paymentMethodId: paymentMethodId }
    }

    try {
      const request = await axios({
        method: "post",
        url:
          process.env.GATSBY_API_BASE_URL +
          (isRetry ? "/plan/retry-invoice" : "/plan/create-subscription"),
        data: data,
      })

      if (isRetry) {
        const response = request.data.invoice

        handleSubscriptionError(
          response.payment_intent,
          response.id,
          paymentMethodId
        )
      } else {
        const response = request.data.subscription

        if (response.status === "active") {
          setProcessing(false)
          setError("")
          setSucceeded(true)
          setOrderCompleted(true)
          await completeGoogleAnalyticsConversion()
          localStorage.removeItem("planId")
          localStorage.removeItem("planNumber")
          localStorage.removeItem("homeCoverPlanId")
        } else {
          /* responseStatus === "incomplete" */
          handleSubscriptionError(
            response.latest_invoice.payment_intent,
            response.latest_invoice.id,
            paymentMethodId
          )
        }
      }
    } catch (error) {
      console.log(error.response)
      setError("Payment card failed, please use another card")
      setProcessing(false)
    }
  }

  const handleSubscriptionError = async (
    paymentIntent,
    invoiceId,
    paymentMethodId
  ) => {
    if (
      paymentIntent.status === "requires_action" ||
      "requires_payment_method"
    ) {
      try {
        const result = await stripe.confirmCardPayment(
          paymentIntent.client_secret,
          {
            payment_method: paymentMethodId,
          }
        )
        if (result.error) {
          setRetrySubscription(true)
          setRetrySubscriptionInvoiceId(invoiceId)
          setError("Authentication failed, please retry or use another card")
          setProcessing(false)
        } else {
          if (result.paymentIntent.status === "succeeded") {
            setProcessing(false)
            setError("")
            setSucceeded(true)
            setOrderCompleted(true)
            localStorage.removeItem("planId")
            localStorage.removeItem("planNumber")
            localStorage.removeItem("homeCoverPlanId")
          } else {
            setRetrySubscription(true)
            setRetrySubscriptionInvoiceId(invoiceId)
            setError("Payment card failed, please retry or use another card")
            setProcessing(false)
          }
        }
      } catch (error) {
        setRetrySubscription(true)
        setRetrySubscriptionInvoiceId(invoiceId)
        setError("Payment card failed, please use another card")
        setProcessing(false)
      }
    } else {
      setRetrySubscription(true)
      setRetrySubscriptionInvoiceId(invoiceId)
      setError("Payment card failed, please use another card")
      setProcessing(false)
    }
  }

  React.useEffect(() => {
    window.dataLayer = window.dataLayer || []
  }, [])

  return (
    <form
      className="payment-form payment-form-subscription"
      onSubmit={(e) => handleSubmit(e, retrySubscription)}
    >
      <p>Payments will be taken on the date of purchase each month.</p>
      <BreakDownTable
        rows={[
          {
            id: "1",
            label: "Monthly amount",
            price: convertPriceToPounds(totalPrice * 100),
          },
        ]}
      />
      <CardElement
        id="card-element"
        options={stripeOptions}
        onChange={handleChange}
      />
      {error && <span className="form-error-text">{error}</span>}
      <ButtonWithLoaderPayment
        isLoading={processing}
        disabled={processing || disabled || succeeded}
        type="submit"
        id="submit"
      >
        <span className="icon-padlock"></span>
        <span className="payment-option-pay-button">Pay now</span>
      </ButtonWithLoaderPayment>
    </form>
  )
}
