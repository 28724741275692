import React from "react"
import PropTypes from "prop-types"

export const CalloutFeeOption = ({
  calloutFee,
  id,
  monthlyPrice,
  name,
  selectOption,
  selected,
}) => {
  return (
    <div className={`callout-fee-option${selected ? " selected" : ""}`}>
      <h3 className="heading-small callout-fee-option-heading">{name}</h3>
      <p className="callout-fee-option-description">
        £{calloutFee.toFixed(2)} callout fee
        <br />
        Repairs free of charge
      </p>
      <button
        onClick={selectOption}
        className="button-primary green callout-fee-option-button"
      >
        {selected && <span className="icon-tick"></span>}
        {monthlyPrice === 0 ? "FREE" : `£${monthlyPrice}/mo`}
      </button>
    </div>
  )
}

CalloutFeeOption.propTypes = {
  calloutFee: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  monthlyPrice: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  selectOption: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
}
