import React from "react"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"

import { ButtonWithLoader, SnackBarOptions } from "../../Shared"
import { CalloutFeeOption } from "./CalloutFeeOption"
import { handleBackendErrors } from "../../../utilities"

export const CalloutFee = ({
  calloutFeeOptions,
  updateSteps,
  updateOrder,
  order,
}) => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)
  const [selectedOption, setSelectedOption] = React.useState({
    calloutFee: 99,
    id: "4OsGVPw2iuP2wFmZlRR1mc",
    monthlyPrice: 0,
    name: "Default",
  })
  const [loading, setIsLoading] = React.useState(false)

  const createNewBreakdown = (oldBreakdown, planBaseCost, calloutFeeOption) => {
    const calloutFeeRow = oldBreakdown.rows.find((row) => row.id === "2")
    const index = oldBreakdown.rows.indexOf(calloutFeeRow)

    const newCalloutFeeRow = {
      ...calloutFeeRow,
      label: "Callout fee: " + calloutFeeOption.name,
      price:
        calloutFeeOption.monthlyPrice === 0
          ? "FREE"
          : `£${calloutFeeOption.monthlyPrice}/mo`,
    }

    const start = oldBreakdown.rows.slice(0, index)
    const end = oldBreakdown.rows.slice(index + 1)

    return {
      rows: [...start, newCalloutFeeRow, ...end],
      total: planBaseCost + calloutFeeOption.monthlyPrice,
    }
  }

  const saveCalloutFeeOption = async () => {
    setIsLoading(true)

    try {
      await axios({
        method: "post",
        url: `${process.env.GATSBY_API_BASE_URL}/plan/select-cover-callout-fee-option`,
        data: {
          planId: order.planId,
          coverCalloutFeeOptionId: selectedOption.id,
        },
      })

      /* Update calloutFees in breakdown rows by slicing array and putting back together */

      const newBreakdown = createNewBreakdown(
        order.breakdown,
        order.homeCoverPlanBaseCost,
        selectedOption
      )
      updateOrder((order) => ({
        ...order,
        steps: {
          ...order.steps,
          calloutFee: {
            ...order.steps.calloutFee,
            result: selectedOption.name,
            data: { ...selectedOption },
          },
        },
        breakdown: {
          ...newBreakdown,
        },
      }))
      updateSteps()
    } catch (error) {
      openSnackbar(handleBackendErrors(error, "save callout fee option"))
    }
    setIsLoading(false)
  }

  return (
    <>
      <section className="control-fees">
        {calloutFeeOptions.map((calloutFeeOption) => (
          <CalloutFeeOption
            key={calloutFeeOption.contentful_id}
            id={calloutFeeOption.contentful_id}
            name={calloutFeeOption.name}
            calloutFee={calloutFeeOption.calloutFee}
            monthlyPrice={calloutFeeOption.monthlyPrice}
            selected={selectedOption.id === calloutFeeOption.contentful_id}
            selectOption={() =>
              setSelectedOption({
                calloutFee: calloutFeeOption.calloutFee,
                id: calloutFeeOption.contentful_id,
                monthlyPrice: calloutFeeOption.monthlyPrice,
                name: calloutFeeOption.name,
              })
            }
          />
        ))}
      </section>
      <ButtonWithLoader
        additionalClasses="button-checkout"
        onClick={saveCalloutFeeOption}
        disabled={loading}
      >
        Enter payment details
      </ButtonWithLoader>
    </>
  )
}
