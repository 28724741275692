import React from "react"

import {
  CheckoutBreakdown,
  CheckoutStep,
  CheckoutStepsContainer,
  CheckoutToolbar,
  CheckoutToolbarItem,
  NavCheckout,
} from "../Shared"
import { smoothScrollToElementWithId } from "../../utilities"

import { Address } from "./Address"
import { Contact } from "./Contact"
import { CalloutFee } from "./CalloutFee"
import { Payment } from "./Payment"
import { BoilerReplacementScheme } from "./BoilerReplacementScheme"

export const CoverOrderContext = React.createContext()

export const CoverCheckout = ({ order, updateOrder, setOrderCompleted }) => {
  const { useCalloutFees } = order

  const [
    boilerReplacementVisible,
    setBoilerReplacementVisibility,
  ] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(1)

  const [breakdownVisibleMobile, setBreakdownVisibleMobile] = React.useState(
    false
  )

  const updateSteps = (step, nextStep, nextStepNumber) => {
    updateOrder((order) => ({
      ...order,
      steps: {
        ...order.steps,
        [step]: {
          ...order.steps[step],
          completed: true,
        },
        [nextStep]: {
          ...order.steps[nextStep],
          disabled: false,
        },
      },
    }))
    setActiveStep(nextStepNumber)
    window.scrollTo({
      top: 0,
    })
  }

  return (
    <CoverOrderContext.Provider value={order}>
      <div className="background-grey-lightest">
        <NavCheckout
          breakdownIcon="shield"
          breakdownText="Your cover"
          setBreakdownVisibleMobile={() => setBreakdownVisibleMobile(true)}
        />
        <CheckoutToolbar
          steps={order}
          activeStep={activeStep}
          totalSteps={Object.keys(order.steps).length}
        >
          <CheckoutToolbarItem
            activeStep={activeStep}
            step={order.steps.address}
            setActiveStep={setActiveStep}
          />
          <CheckoutToolbarItem
            activeStep={activeStep}
            step={order.steps.contact}
            setActiveStep={setActiveStep}
          />
          {useCalloutFees && (
            <CheckoutToolbarItem
              activeStep={activeStep}
              step={order.steps.calloutFee}
              setActiveStep={setActiveStep}
            />
          )}
          <CheckoutToolbarItem
            activeStep={activeStep}
            step={order.steps.payment}
            setActiveStep={setActiveStep}
            chevron={false}
          />
        </CheckoutToolbar>
        <CheckoutStepsContainer>
          <CheckoutStep
            activeStep={activeStep}
            step={order.steps.address}
            setActiveStep={setActiveStep}
          >
            <Address
              order={order}
              updateOrder={updateOrder}
              updateSteps={() =>
                updateSteps("address", "contact", order.steps.contact.number)
              }
            />
          </CheckoutStep>
          <CheckoutStep
            activeStep={activeStep}
            step={order.steps.contact}
            setActiveStep={setActiveStep}
          >
            <Contact
              order={order}
              updateOrder={updateOrder}
              updateSteps={() =>
                updateSteps(
                  "contact",
                  useCalloutFees ? "calloutFee" : "payment",
                  useCalloutFees
                    ? order.steps.calloutFee.number
                    : order.steps.payment.number
                )
              }
              nextStepText={
                useCalloutFees
                  ? "Choose your callout fee"
                  : "Enter payment details"
              }
            />
          </CheckoutStep>
          {useCalloutFees && (
            <CheckoutStep
              activeStep={activeStep}
              step={order.steps.calloutFee}
              setActiveStep={setActiveStep}
            >
              <CalloutFee
                calloutFeeOptions={order.homeCoverPlan.calloutFeeOptions}
                order={order}
                updateOrder={updateOrder}
                updateSteps={() =>
                  updateSteps(
                    "calloutFee",
                    "payment",
                    order.steps.payment.number
                  )
                }
              />
            </CheckoutStep>
          )}
          <CheckoutStep
            activeStep={activeStep}
            step={order.steps.payment}
            setActiveStep={setActiveStep}
          >
            <Payment
              setOrderCompleted={setOrderCompleted}
              planId={order.planId}
              totalPrice={order.breakdown.total}
            />
          </CheckoutStep>
        </CheckoutStepsContainer>
        <CheckoutBreakdown
          changeLinkUrl="/home-cover-plans"
          isVisibleMobile={breakdownVisibleMobile}
          useStrings={true}
          dismissFunc={() => setBreakdownVisibleMobile(false)}
          heading="Your cover"
          rows={order.breakdown.rows}
          total={`£${order.breakdown.total}/mo`}
        >
          <div className="boiler-checkout-breakdown-buttons">
            <button
              className="boiler-checkout-save-quote-button"
              onClick={() => setBoilerReplacementVisibility(true)}
            >
              What if your boiler can't be fixed?
            </button>
          </div>
        </CheckoutBreakdown>
        <BoilerReplacementScheme
          isVisible={boilerReplacementVisible}
          dismissFunc={() => setBoilerReplacementVisibility(false)}
        />
      </div>
    </CoverOrderContext.Provider>
  )
}
